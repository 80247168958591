import Swal from 'sweetalert2'

export default function (e, cb=null){
  console.log(e)
  if(!e?.data?.hideAlert) {
    Swal.fire({
      title: e?.title || 'ผิดพลาด!',
      text: (e?.message || e?.text) || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
      icon: e?.icon || 'error',
      confirmButtonText: e?.confirmButtonText || 'OK'
    })
    .then(()=>{
      if(typeof cb === 'function')
        cb()
    })
  }
}
